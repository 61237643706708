<template>
  <footer class="footer-wrap bg-rock" style="background-color: #561C24" v-if="$store.state.company != null">
    <div class="copyright-text">
      <p>
        <i class="ri-copyright-line"></i>{{ currentYear }} {{ $store.state.company.copyright }}
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
