<template>
    <div class="about-wrap style1 ptb-100 direction-right bg-squeeze" v-if="$store.state.hero != null">
        <div class="container">
            <div class="row gx-5 align-items-center">
                <div class="col-lg-6">
                    <div class="about-img-wrap">
                        <img  :src="$store.state.url + $store.state.hero.image" style="height: 500px; width: auto" alt="Image" class="about-img">
                    </div>
                </div>
                <div class="col-lg-6" style="direction: rtl">
                    <div class="about-content">
                        <div class="content-title style1">
                          <span>{{ $store.state.hero.subtitle }}</span>
                            <h2>{{ $store.state.hero.title }}</h2>
                            <p style="text-align: justify">
                              {{ $store.state.hero.description }}
                            </p>
                        </div>
                        <ul class="content-feature-list style1 list-style" v-if="$store.state.hero.aims != null && $store.state.hero.aims.toString().split(',').length > 0">
                          <li v-for="item in $store.state.hero.aims.toString().split(',')">{{ item }}</li>
                        </ul>
                        <a target="_blank" href="https://www.facebook.com/p/%D8%B5%D9%81%D8%AD%D8%A9-%D9%85%D8%AA%D8%AD%D9%81-%D8%A7%D9%84%D9%85%D8%AC%D8%A7%D9%87%D8%AF-%D9%84%D9%88%D9%84%D8%A7%D9%8A%D8%A9-%D8%A8%D8%B4%D8%A7%D8%B1-100082291522929/" class="btn style1">اكتشف أكثر عنا</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>

<style>
.pent{
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
  border-radius: 30px;
}
</style>