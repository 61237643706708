<template>
    <div class="page-wrapper">
      <Navbar class="style1" />
      <br>
      <br>
      <br>
      <br>
      <About/>
      <MujahideenAndMartyrs id="mujahidon"/>
      <Blog id="articles"/>
      <AppDownload id="appd"/>
      <Footer/>
    </div>
</template>

<script>
import Navbar from './Navbar'
import Footer from './Footer'
import MainBanner from "@/components/MainBanner";
import About from "@/components/About";
import MujahideenAndMartyrs from "@/components/MujahideenAndMartyrs";
import Blog from "@/components/Blog";
import AppDownload from "@/components/AppDownload";

export default {
    name: 'Home',
    components: {
      AppDownload,
      Blog,
      MujahideenAndMartyrs,
      About,
      MainBanner,
      Navbar,
      Footer,
    }
}
</script>