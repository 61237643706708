import { createWebHistory, createRouter } from "vue-router";

import Home from "../components/Home";

const routes = [
  { path: "/", name: "Home", component: Home },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
