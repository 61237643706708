<template>
    <div class="blog-wrap pt-100 pb-75" v-if="$store.state.articles != null">
        <div class="container">
            <div class="section-title style1 text-center mb-40">
                <span>اقرأ واتابع</span>
                <h2>مقالات تاريخية</h2>
            </div>
            <div class="row justify-content-center" v-if="$store.state.articles.length > 0">
                <div class="col-xl-4 col-lg-6 col-md-6" v-for="article in $store.state.articles">
                    <div class="blog-card style1">
                        <div class="blog-img">
                            <img :src="$store.state.url + article.image" style="width: 540px; height: 250px" alt="Image">
                        </div>
                        <div class="blog-info">
                            <span style="margin-left: 10px"><i class="flaticon-user" style="margin-left: 10px"></i> {{ article.author }}  </span>
                            <h3 class="mt-1"><router-link to="/">{{ article.title }}</router-link></h3>
                            <router-link to="/" class="link style1">اقرأ المزيد
                              <i class="flaticon-left-arrow"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Blog'
}
</script>