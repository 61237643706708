<template>
  <div class="app-wrap style3 ptb-100 bg-albastor" v-if="$store.state.app != null">
    <div class="container">
      <div class="row align-items-center gx-5">
        <div class="col-lg-6">
          <div class="app-img-wrap">
            <img class="bounce" :src="$store.state.url + $store.state.app.image" style="height: 450px; width: auto" alt="Image">
            <img class="app-shape-one" src="../assets/images/app/app-shape-2.png" style="margin-left: 80px" alt="Image">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="app-content">
            <div class="content-title style1">
              <span>{{ $store.state.app.subtitle }}</span>
              <h2>{{ $store.state.app.title }}</h2>
              <p>{{ $store.state.app.description }}</p>
            </div>
            <div class="app-btn">
              <a target="_blank" href="" class="btn style1">تحميل التطبيق</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppDownload'
}
</script>