// store.js

import Vuex from 'vuex';
import axios from './axios';


export default new Vuex.Store({
    state: {
        url: "",
        company: null,
        hero: null,
        mujahidin: [],
        articles: [],
        loading: false,
        app: null,
    },
    mutations: {
        setUrl(state, data) {
            state.url = data;
        },
        setCompany(state, data) {
            state.company = data;
        },
        setLoading(state, status) {
            state.loading = status;
        },
        setHero(state, status) {
            state.hero = status;
        },
        setMujahidin(state, status) {
            state.mujahidin = status;
        },
        setArticles(state, status) {
            state.articles = status;
        },
        setApp(state, status) {
            state.app = status;
        },
    },
    actions: {
        fetchDataFromApi({ commit }) {
            commit('setLoading', true);
            axios.get('/get-data')
                .then(response => {
                    commit('setUrl', response.data.base_url);
                    commit('setCompany', response.data.company);
                    commit('setHero', response.data.hero);
                    commit('setMujahidin', response.data.mujahidin);
                    commit('setArticles', response.data.articles);
                    commit('setApp', response.data.app);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                })
                .finally(() => {
                    commit('setLoading', false);
                });
        },
    },
});


/*



<template>
    <div class="account-wrap pb-5 pt-5" v-if="$store.state.trainings != null">
        <div class="container">
            <div class="section-title style1 text-center mb-40">
<!--                <span>Open Account</span>-->
                <h2>Training Registrations</h2>
            </div>
            <form @submit.prevent class="account-form">
                <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="fname">Full name</label>
                        <input type="text" id="fname" name="fname">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="phone">Mobile Number</label>
                        <input type="number" id="phone" name="phone">
                      </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="email">Email Address</label>
                            <input type="email" id="email" name="email">
                        </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="dob">Address</label>
                        <input type="text" id="address" name="address">
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="gender">Gender</label>
                        <select name="gender" id="gender">
                          <option value="0">Gender</option>
                          <option value="1">Male </option>
                          <option value="2">Female </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="dob">Payment Receipt</label>
                        <input type="file" id="receipt" name="receipt"  accept="image/*">
                      </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <h4>Choose your training</h4>
                    </div>
                    <div class="row justify-content-center">
                      <div v-for="(training, index) in getTrainings()" class="col-xl-4 col-lg-6 col-md-6" @click="checkCart(index)">
                        <div class="service-card style1" v-if="tutorials.indexOf(index) >= 0" style="background-color: #e7e7e7">
                          <span class="service-icon">
                            <img
                                :src="training.icon"
                                alt="Image"
                            />
                          </span>
                          <h3>
                            <router-link to="/service-details"
                            >{{ training.title }}</router-link
                            >
                          </h3>
                          <p v-html="training.short_description"></p>
                        </div>
                        <div class="service-card style1" v-else>
                          <span class="service-icon">
                            <img
                                :src="$store.state.url + training.logo"
                                alt="Image"
                            />
                          </span>
                          <h3>
                            <router-link to="/service-details"
                            >{{ training.title }}</router-link
                            >
                          </h3>
                          <p v-html="training.short_description"></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-5">
                        <button class="btn style1 w-100 d-block">Register Now ({{total}} DA)</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Account',
  methods: {
    checkCart(index) {
      if(this.tutorials.indexOf(index) >= 0){
        this.tutorials.pop(index)
        this.total -= this.trainings[index].numeric_price
      }else{
        this.tutorials.push(index)
        this.total += this.trainings[index].numeric_price
      }
    },
    getTrainings() {
      this.trainings = this.$store.state.trainings
      return this.trainings
    }
  },
  data: () => ({
    total: 0.00,
    tutorials: [],
    trainings: []
  }),
}
</script>
 */