<template>
    <div class="team-wrap pt-100 pb-75 bg-albastor" v-if="$store.state.mujahidin != null">
        <div class="container">
          <div class="section-title style1 text-center mb-40">
            <span>البعض من</span>
            <h2>مجاهدو وشهداء المنطقة</h2>
          </div>
            <div class="row justify-content-center" v-if="$store.state.mujahidin.length > 0">
              <div class="col-xl-3 col-lg-3 col-md-6" v-for="mujahid in $store.state.mujahidin">
                    <div class="team-card style1">
                        <div class="team-img">
                            <img :src="$store.state.url + mujahid.image" style="width: 540px; height: 290px" alt="Image">
                          <ul class="social-profile list-style style1">
                            <li>
                              <a target="_blank" @click="toggleModal2(mujahid.key, mujahid.full_name)">
                                <i class="ri-qr-code-fill"></i>
                              </a>
                            </li>
                            <li>
                              <a target="_blank" @click="toggleModal(mujahid.video, mujahid.full_name)">
                                <i class="ri-video-fill"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div class="team-info">
                            <h3>{{ mujahid.full_name }}</h3>
                            <span>من {{ mujahid.birth_date }} الى {{ mujahid.die_date }}</span>
                        </div>
                    </div>
              </div>
            </div>
        </div>


      <b-modal v-model="modalShow" hide-footer :title="'  ' + currentShaheed + '  '">
        <iframe v-if="currentUrl != null && currentUrl != ''" class="embed-responsive-item" :src="$store.state.url + currentUrl" allowfullscreen style="width: 100%; height: 300px"></iframe>
        <p v-else>لا يزال العمل قائما على هذا الفيديو</p>
      </b-modal>

      <b-modal v-model="modalShow2" style="text-align: center" :title="'  ' + currentShaheed + '  '" hide-footer>
        <b-row align-content="center" align-h="center" align-v="center">
          <b-col cols="3"></b-col>
          <b-col cols="6">
            <qrcode text="https://bechar-ai-forum.techinnov.dz/registrations" style="text-align: center"></qrcode>
          </b-col>
          <b-col cols="3"></b-col>
        </b-row>
      </b-modal>



    </div>
</template>

<script>
import VueQrcodeComponent from 'vue-qrcode-component';

export default {
    name: 'MujahideenAndMartyrs',
  components: {
    // Register the QRCode component
    qrcode: VueQrcodeComponent
  },
  data() {
    return {
      modalShow: false,
      modalShow2: false,
      currentUrl: "",
      currentShaheed: "",
      currentQR: "",
    }
  },
  methods: {
      toggleModal(url, shaheed) {
        this.currentUrl = url
        this.currentShaheed = shaheed
        this.modalShow = !this.modalShow
      },
    toggleModal2(key, shaheed) {
      this.currentQR = key
      this.currentShaheed = shaheed
      this.modalShow2 = !this.modalShow2
    },
  }
}
</script>