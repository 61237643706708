<template>
  <div :class="['header-wrap', { sticky: isSticky }]" v-if="$store.state.company != null">
    <!-- Top Header -->
    <div class="header-top" :class="{ open: open }" style="background-color: #561C24">
      <button type="button" class="close-sidebar" @click="open = !open">
        <i class="ri-close-fill"></i>
      </button>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-9">
            <div class="header-top-left direction-right text-white">
              <span v-if="showAd">{{ $store.state.company.last_event }}</span>
              <span style="color:#561C24;" v-else>متحف بشار يدعوكم للإحتفال باليوم الوطني للشهيد الموافق لـ 18-02-2024</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Top Header -->

    <!-- Navbar -->
    <div class="header-bottom">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
          <router-link class="navbar-brand" to="/">
            <img
              class="logo-light"
              :src="$store.state.url + $store.state.company.logo"
              alt="logo"

            />
            <img
              class="logo-dark"
              :src="$store.state.url + $store.state.company.logo"
              alt="logo"
            />
          </router-link>

          <div class="collapse navbar-collapse direction-right" :class="{ show: active }">
            <div class="others-options d-flex align-items-center">
              <ul class="navbar-nav ms-auto">

                <li class="nav-item">
                  <a class="nav-link" href="#appd">المكتبة الرقمية</a>
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="#articles">مقالات تاريخية</a>
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="#mujahidon">مجاهدوا وشهداء المنطقة</a>
                </li>


                <li class="nav-item">
                  <router-link to="/" class="nav-link">الرئيسية</router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="mobile-bar-wrap">
            <div
              class="mobile-sidebar"
              @click="open = !open"
              :aria-pressed="open ? 'true' : 'false'"
              v-bind:class="{ open: button_open_state }"
              v-on:click="button_open_state = !button_open_state"
            >
              <i class="ri-menu-4-line"></i>
            </div>
            <div
              class="navbar-toggler mobile-menu xl-none"
              @click="active = !active"
              :aria-pressed="active ? 'true' : 'false'"
              v-bind:class="{ active: button_active_state }"
              v-on:click="button_active_state = !button_active_state"
            >
              <a href="javascript:void(0);">
                <i class="ri-menu-line"></i>
                <i class="ri-close-line"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>

      <!-- Search Modal -->
      <div class="search-area" :class="{ search: search }">
        <div class="container">
          <form @submit.prevent>
            <div class="form-group">
              <input type="search" placeholder="Search Here" autofocus />
            </div>
          </form>
          <button
            type="button"
            class="close-searchbox"
            @click="search = !search"
          >
            <i class="ri-close-line"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- End Navbar -->
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data: () => ({
    isSticky: false,
    active: false,
    button_active_state: false,
    search: false,
    button_search_state: false,
    open: false,
    button_open_state: false,
    showAd: true
  }),
  mounted() {
    const that = this;
    this.toggleAd();
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
  methods: {
    toggleAd() {
      setInterval(() => {
        this.showAd = !this.showAd;
      }, 1000);
    }
  }
};
</script>


